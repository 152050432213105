::-webkit-scrollbar{
  width: 5px;
}

::-webkit-scrollbar-thumb{
  background-color: #777777;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover{
  background-color: #a0a0a0;
}

@media print{
  *{
    color: black !important;
  }
  table, thead, tbody, th, tr, td{
    border: 1px solid black !important;
  }
  header{
    display: none !important;
  }
}